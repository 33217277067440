import React from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

function Home() {
    return (
      <>
      <div className="row">
      <div className="col d-none d-md-block">
        <div className='videoPlayer'>
          <ReactPlayer  
          url='./assets/cg_video.mp4'
          playing ={true}
          loop = {true}
          volume={0}
          muted = {true}
          width='100%'
          height='100%'
        />
        </div>
      </div>
    </div>
    <div className="row pt-5 pt-3 pb-5 silverBackground">
      <div className="col">
        <div className="text-center display-5">Unlock Unmatched Capabilities<br/>
        <Link to="/capabilities">
        <button type="button" className="btn btn-primary btn-lg mt-5">VIEW CAPABILITIES</button>
        </Link>
        </div>
      </div>
    </div>
      <div className="row mt-5 pt-3">
        <div className="col-md-6 col-sm-12 ps-5">
          <span className='text-center display-5'>
           TRANSFORMATIONAL LEADERSHIP
          </span>
          <p>
          Successful Modernization Governance requires
          Agile customer engagement with strong relationships
          between Development, Cyber/Information Assurance,
          and Infrastructure teams. 
          </p>
          <p>Our AppSynLabs Team combines tailored SAFe®
            training with Program Governance to build Stakeholder
            preparedness for modernized capabilities.
          </p>
        </div>
        <div className="col-md-6 col-sm-12 pt-0 ps-5">
          <div className='row'>
            <div className='col-auto'>
              <img src='./assets/chevron_bullet.png'
                alt=''
                width={39}
                height={39}
                className='img-fluid'
              />
            </div>
            <div className='col-auto'>
              <div className='list_item ps-3'>
              100 Day MVP<br/>
              10x and counting
              </div>
            </div>
          </div>
          <div className='row pt-3'>
            <div className='col-auto'>
              <img src='./assets/chevron_bullet.png'
                alt=''
                width={39}
                height={39}
                className='img-fluid'
              />
            </div>
            <div className='col-auto'>
              <div className='list_item ps-3'>
              100+ Agile Certification<br/>Classes Taught
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-5 pt-3 mb-5 pb-5 silverBackground">
      <div className="row mt-5 pt-3">
        <div className="col-md-6 col-sm-12 p-5">
          <div className='row'>
              <div className='col-auto'>
                <img src='./assets/chevron_bullet.png'
                  alt=''
                  width={39}
                  height={39}
                />
              </div>
              <div className='col'>
                <div className='list_item ps-3'>
                  Synergy Nautilus Software Factory, following
                  the DoD DevSecOps Playbook, delivers IA-approved
                  artifacts for modernizing over 10 systems
                </div>
              </div>
          </div>
            <div className='row pt-3'>
              <div className='col-auto'>
                <img src='./assets/chevron_bullet.png'
                  alt=''
                  width={39}
                  height={39}
                />
              </div>
              <div className='col'>
                <div className='list_item ps-3'>
                  We collaborate with IA and Infrastructure
                  teams to implement DoD Zero Trust, beginning
                  with application architecture
                </div>
              </div>
            </div>
          {/* <ul>
            <li className='list_item'>
            Synergy Nautilus Software Factory, following
            the DoD DevSecOps Playbook, delivers IA-approved
            artifacts for modernizing over 10 systems
            </li>
            <li className='list_item pt-4'>
            We collaborate with IA and Infrastructure teams to implement DoD Zero Trust, beginning with application architecture
            </li>
          </ul> */}
        </div>
        <div className="col-md-6 col-sm-12 p-5 order-first order-md-last">
          <span className='text-center display-5'>
             TECHNICAL TRANSFORMATION
          </span>
          <p>
          Low Code Application Platforms, Microservices, 
          and Software Factory together complete the 
          technology puzzle with a focus on early and 
          often releases for the field while increasing 
          quality and improving sustainment efficiency.
          </p>
          <div>
          <img src="./assets/transform_badges.webp"
          alt='Appian, Microsoft Power, SalesForce, ServiceNow'
          className='img-fluid'
          width={571}
          height={81}
          />
          </div>
        </div>
      </div>
      </div>
      <div className="row mt-5 pt-3">
        <div className="col-md-6 col-sm-12 p-5">
          <span className='text-center display-5'>
          CAPABILITY MODERNIZATION
          </span>
          <p>
          AppSynLabs recognizes that while Capability
          Modernization is a gradual process, enhancements
          in Sustainment can minimize technical debt, cut
          sustainment expenses, and simplify the shift to
          system modernization. 
          </p>
        </div>
        <div className="col-md-6 col-sm-12 p-5">
        <div className='row'>
              <div className='col-auto'>
                <img src='./assets/chevron_bullet.png'
                  alt=''
                  width={39}
                  height={39}
                />
              </div>
              <div className='col'>
                <div className='list_item ps-3'>
                200% increase in release cadence while improving release quality
                </div>
              </div>
        </div>
        <div className='row pt-3'>
              <div className='col-auto'>
                <img src='./assets/chevron_bullet.png'
                  alt=''
                  width={39}
                  height={39}
                />
              </div>
              <div className='col'>
                <div className='list_item ps-3'>
                Our Configuration Management Database includes
          Automated STIG checks, STIG remediation and certificate tracking
                </div>
              </div>
        </div>
          
         {/* <ul>
          <li className='list_item'>
          200% increase in release cadence while
          improving release quality
          </li>
          <li className='list_item pt-3'>
          Our Configuration Management Database includes
          Automated STIG checks, STIG remediation and certificate tracking.
          </li>
         </ul> */}
        </div>
      </div>
      <div className="container-fluid mt-3 pt-3 pb-3 silverBackground">
        <div className='row'>
          <div className="col-12 p-4">
            <div className="text-center h1">Let's get your project started.<br/>
             <a href="mailto:tporter@appddictionstudio.com?subject=AppSynLabs Contact">
              <button type="button" className="btn btn-primary btn-lg mt-5"
              >CONTACT US</button>
            </a>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div className="modal fade" id="contactModal" tabIndex="-1" aria-labelledby="contactModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title fs-5 h1 col text-center"
                id="contactModalLabel">CONTACT US
                </div>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              ...
            </div>
            <div className="col text-center pt-3 pb-4">
              <button type="button" className="btn btn-primary w-75">Save changes</button>
            </div>
          </div>
        </div>
      </div>
  </>
    )
}

export default Home;
