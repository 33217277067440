import React from 'react';

function Capabilities() {
  return (
    <>
    <div className="row">
        <div className="col">
          <img alt=''
            className='img-fluid w-100'
            srcSet='./assets/capa_hero-300.jpg 300w, ./assets/capa_hero-1180.jpg 1180w, ./assets/capa_hero.webp 1642w, ./assets/capa_hero-2048.jpg 2048w'
            src="./assets/capa_hero.jpg"
            sizes='100vw'
            width={1642}
            height={486}
          />
        </div>
      </div>
    <div className="container-fluid">
      <div className="row mt-5">
        <div className="col-md-6 col-sm-12 p-5 text-center">
          <img src="./assets/cloud_data.png"
          alt='' 
          className='img-fluid'
          width={454}
          height={284}
        />
        </div>
        <div className="col-md-6 col-sm-12 p-5 pt-0">
          <span className='text-center display-5'>
            CLOUD SOLUTIONS
          </span>
          <div className='long_paragraph'>At AppSynLabs, our expertise in cloud solutions extends beyond mere migration. We understand that the modern landscape demands more than just moving applications to the cloud; it requires a strategic approach that optimizes performance, security, and scalability. 
          </div>
          <div className='row pt-3'>
            <div className='col-auto'>
              <img src='./assets/chevron_bullet.png'
                alt=''
                width={28}
                height={28}
              />
            </div>
            <div className='col'>
              <div className='list_item ps-3'>
              Specializing in migrating legacy applications to the Cloud
              </div>
            </div>
          </div>
          <div className='row pt-3'>
            <div className='col-auto'>
              <img src='./assets/chevron_bullet.png'
                alt=''
                width={28}
                height={28}
              />
            </div>
            <div className='col'>
              <div className='list_item ps-3'>
              Utilize a Software Factory and DevSecOps pipeline for secure, compliant transitions
              </div>
            </div>
          </div>
        </div>
        <div className='col ps-5 pe-5'>
        <div className='long_paragraph'>With our focus on automation, continuous integration, and deployment, we propel software delivery to new heights. By streamlining processes and minimizing manual intervention, we not only accelerate delivery timelines but also mitigate risks associated with human error.</div>
          <div className='long_paragraph'>One of our core specialties lies in migrating legacy applications to the cloud seamlessly. We recognize the unique challenges posed by legacy systems and have developed tailored strategies to ensure smooth transitions. Whether it's rearchitecting applications for cloud-native environments or optimizing existing infrastructures, we leverage our expertise to deliver optimal outcomes.</div>
          <div className='long_paragraph'>At the heart of our approach is the utilization of a Software Factory and DevSecOps pipeline. This integrated framework enables us to maintain rigorous security standards and compliance requirements throughout the migration process. By embedding security practices into every stage of development and deployment, we ensure that your applications are not only cloud-ready but also resilient to emerging threats.</div>
          <div className='long_paragraph'>In essence, AppSynLabs doesn't just migrate applications to the cloud; we transform them into agile, secure, and future-proof assets that drive your business forward. With our comprehensive suite of cloud solutions and a commitment to excellence, we empower organizations to embrace the full potential of the cloud with confidence.
        </div>
    </div>
      </div>  
    </div>

    <div className="container-fluid silverBackground">
      <div className="row mt-5">
        <div className="col-md-6 col-sm-12 pt-5 ps-5 pe-5">
            <div className='display-5'>
              NO CODE/LOW CODE
            </div>
            <div className='long_paragraph'>
              Step into the future of software development with AppSynLabs' innovative No Code/Low Code solutions.
              Say farewell to the complexities of traditional coding and usher in a new era of rapid application creation.
            </div>
        </div>
          <div className="col-md-6 col-sm-12 pt-5 ps-5 pe-5 text-center order-first order-md-last">
            <img src="./assets/no_code_low_code.png"
            alt='' 
            className='pt-5 img-fluid'
            width={541}
            height={190}
            />
          </div>
        </div>
        <div className='col ps-5 pe-5 pb-5 pt-md-5'>
          <div className='long_paragraph'>
            Our platforms provide a user-friendly environment that empowers individuals across your organization to build robust applications, regardless of their technical expertise. Gone are the days of lengthy development cycles and dependence on specialized coding skills. With our No Code/Low Code approach, you can unleash the full potential of your business by accelerating the creation of custom applications. Whether you're looking to streamline internal processes, enhance customer experiences, or launch innovative products, our platforms offer the flexibility and agility you need to bring your ideas to life.
          </div>
          <div className='long_paragraph'>
            Imagine empowering your teams to build applications tailored to their unique requirements without the need for extensive programming knowledge. With intuitive drag-and-drop interfaces, pre-built templates, and powerful integrations, our No Code/Low Code platforms make application development accessible to everyone. But accessibility doesn't mean compromising on functionality or security. Our solutions are built with enterprise-grade features and robust security measures to ensure that your applications are not only powerful but also reliable and secure.
            </div>
              <div className='long_paragraph'>
                Embrace the future of software development with AppSynLabs' No Code/Low Code solutions and unlock endless possibilities for innovation and growth. Say hello to a world where anyone can be a software creator and where the only limit is your imagination.
              </div>
          </div>
    </div>
    <div className="container-fluid">
      <div className="row mt-5">
        <div className="col-md-6 col-sm-12 p-5 text-center">
          <img src="./assets/code_bracket.png"
            alt='' 
            className='img-fluid'
            width={273}
            height={205}
            />
        </div>
        <div className="col-md-6 col-sm-12 p-5 pt-0">
          <span className='text-center display-5'>
            SOFTWARE DEVELOPMENT
          </span>
          <div className='long_paragraph'>
          Embark on a transformational journey with AppSynLabs as your trusted partner in software development.
          </div>
          <div className='row pt-3'>
            <div className='col-auto'>
              <img src='./assets/chevron_bullet.png'
                alt=''
                width={28}
                height={28}
              />
            </div>
            <div className='col'>
              <div className='list_item ps-3'>
              Faster time-to-market for products and services
              </div>
            </div>
          </div>
          <div className='row pt-3'>
            <div className='col-auto'>
              <img src='./assets/chevron_bullet.png'
                alt=''
                width={28}
                height={28}
              />
            </div>
            <div className='col'>
              <div className='list_item ps-3'>
                Improved product quality from adoption of new techniques
              </div>
            </div>
          </div>
        </div>
        <div className='col ps-5 pe-5'>
          <div className='long_paragraph'>
            Our team of experienced professionals is dedicated to guiding you through every step of the process, ensuring that you remain at the forefront of the rapidly evolving software development industry. With our expertise and innovative approaches, we help you achieve faster time-to-market for your products and services.
          </div>
          <div className='long_paragraph'>
            By streamlining development processes, embracing agile methodologies, and leveraging cutting-edge technologies, we empower you to deliver solutions to market with unprecedented speed and efficiency. But speed alone is not our only focus. We understand the importance of quality in today's competitive landscape. That's why we place a strong emphasis on adopting new techniques and best practices to continually improve product quality. Whether it's implementing rigorous testing protocols, embracing automation, or fostering a culture of continuous improvement, we work tirelessly to ensure that your products meet the highest standards of excellence. 
          </div>
          <div className='long_paragraph'>
          At AppSynLabs, we don't just develop software; we craft transformative solutions that drive business success. With our unwavering commitment to innovation, quality, and customer satisfaction, we empower you to realize your vision and achieve your goals in the ever-changing world of software development.
          </div>
        </div>
      </div> 
    </div>
    <div className="container-fluid silverBackground">
      <div className="row mt-5">
        <div className="col-md-6 col-sm-12 ps-5 pt-5">
          <span className='text-center display-5'>
          SAFe® Agile
          </span>
          <div className='long_paragraph'>
            At AppSynLabs, we recognize the importance of adopting Agile methodologies to
            drive organizational agility and accelerate value delivery.
            That's why we embrace the Scaled Agile Framework (SAFe) as a proven approach to
            scaling Agile practices across the enterprise. 
          </div>
        </div>
        <div className="col-md-6 col-sm-12 ps-5 pe-5 text-center order-first order-md-last">
          <img src="./assets/scaled_agile_dteal.png"
          alt='' 
          className='mt-5 img-fluid w-100'
          width={334}
          height={120}
          />
        </div>
      </div>
      <div className='row ps-5 pe-5'>
          <div className='col'>
            <div className='long_paragraph'>
              SAFe provides a comprehensive framework that enables organizations to scale Agile principles beyond individual teams to entire enterprises. By aligning strategy, execution, and delivery at all levels of the organization, SAFe fosters collaboration, transparency, and alignment across departments, business units, and even partner organizations. We organize cross-functional teams into Agile Release Trains (ARTs), each focused on delivering value within a specific timeframe, typically 8-12 weeks. These teams are empowered to plan, execute, and deliver high-quality solutions independently, while remaining aligned with the larger organizational goals.
            </div>
            <div className='long_paragraph'>
             We facilitate PI Planning events, where all members of the ART come together to plan the upcoming increment collaboratively. During PI Planning, teams establish objectives, identify dependencies, and synchronize their efforts to ensure a cohesive and integrated delivery approach.
             </div>
             <div className='long_paragraph'>We establish a Continuous Delivery Pipeline that enables the rapid and reliable delivery of value to customers. By automating build, test, and deployment processes, we reduce lead times, improve quality, and increase the frequency of releases, allowing organizations to respond quickly to changing market demands.</div>
             <div className='long_paragraph'>
             We foster a culture of continuous improvement through regular Inspect and Adapt (I&A) workshops. These sessions provide opportunities for teams and stakeholders to reflect on their performance, identify areas for improvement, and adjust their practices accordingly, ensuring that the organization remains adaptive and resilient in the face of change.</div>
             <div className='long_paragraph'>We apply Lean principles to portfolio management, prioritizing initiatives based on their strategic value, economic impact, and resource constraints. By adopting a lean and agile approach to portfolio management, organizations can maximize the return on investment and ensure that resources are allocated effectively to support strategic objectives. By embracing SAFe Agile principles and practices, we enable organizations to unlock their full potential, drive innovation, and deliver value to customers faster and more efficiently than ever before.</div>
             <div className='long_paragraph'>With AppSynLabs as your partner, you can confidently navigate the complexities of enterprise-scale Agile transformation and achieve sustainable business success in today's rapidly evolving digital landscape.
            </div>
          </div>
        </div>
    </div>
    <div className="container-fluid pt-5 pb-5">
      <div className='row'>
        <div className="col-12 p-4">
          <div className="text-center display-5">
            Let's get your project started.<br/>
           <a href="mailto:tporter@appddictionstudio.com?subject=AppSynLabs Contact">
            <button type="button" className="btn btn-primary btn-lg mt-5"
            >CONTACT US</button>
          </a>
          </div>
        </div>
      </div>
    </div>
  </>
  );
}

export default Capabilities;