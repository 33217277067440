import { Link } from 'react-router-dom';

function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src="./assets/appsynlabs_logo.svg"
              alt="Appddiction Synergy Labs"
              width={279}
              height={89} />
          </Link>
          <button className="navbar-toggler" type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/">HOME</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/capabilities">CAPABILITIES</Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="/performance">PERFORMANCE</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/people">PEOPLE</Link>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    )
}

export default Navbar;