function Footer() {
    return(
        <div className="container-fluid footerBackground">
          <div className='row'>
            <div className="col-12 p-4">
              <div className="text-center">
              <img src="./assets/appsynlabs_logo.svg"
                alt="Appddiction Synergy Labs"
                width={279}
                height={89}
            />
              </div>
              <div className='text-center h2 pt-2 pb-2'>
                210-859-9677
              </div>
            </div>
          </div>
        </div>
    )
}
export default Footer;